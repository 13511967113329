import { SimpleGrid, Skeleton } from '@chakra-ui/react';
import React from 'react';

function LoadingPlaceholder() {
  return (
    <SimpleGrid spacing={4} columns={{ base: 1, lg: 2, xl: 3 }}>
      <Skeleton height="200px" />
      <Skeleton height="200px" />
      <Skeleton height="200px" />
    </SimpleGrid>
  );
}

export default LoadingPlaceholder;
