import React, { useState } from 'react';
import PasswordForm from '../../pages/authentication/PasswordForm';
import { Credentials, CredentialsContext } from '../../credentials';

const getCredentialsFromLocalStorage = (): Credentials | undefined => {
  const credentialsJson = localStorage.getItem('credentials');
  if (!credentialsJson) {
    return undefined;
  }
  return JSON.parse(credentialsJson);
};

const setCredentialsInLocalStorage = (credentials: Credentials): void => {
  localStorage.setItem('credentials', JSON.stringify(credentials));
};

type Props = {
  children: React.ReactNode;
};

function PasswordProtected({ children }: Props) {
  const [credentials, setCredentials] = useState<Credentials | undefined>(() =>
    getCredentialsFromLocalStorage()
  );

  const setCredentialsStateAndLocalStorage = (
    credentials: Credentials
  ): void => {
    setCredentials(credentials);
    setCredentialsInLocalStorage(credentials);
  };

  return !credentials ? (
    <PasswordForm onSuccess={setCredentialsStateAndLocalStorage} />
  ) : (
    <CredentialsContext.Provider value={credentials}>
      {children}
    </CredentialsContext.Provider>
  );
}

export default PasswordProtected;
