import React from 'react';
import { User } from '../../user';
import { SimpleGrid } from '@chakra-ui/react';
import UserGiftCard from '../../components/gift/UserGiftCard';

type Props = {
  readonly users: User[];
};

function UserGifts({ users }: Props) {
  return (
    <SimpleGrid spacing={4} columns={{ base: 1, lg: 2, xl: 3 }}>
      {users.map((user) => (
        <UserGiftCard key={user.username} user={user} />
      ))}
    </SimpleGrid>
  );
}

export default UserGifts;
