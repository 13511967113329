import React, { useContext } from 'react';
import Home from '../home/Home';
import PasswordProtected from '../../components/password-protected/PasswordProtected';
import { Box, Heading } from '@chakra-ui/react';
import { VariantContext } from '../../variants';
import UserContextProvider from '../../components/user-context/UserContextProvider';

function App() {
  const variant = useContext(VariantContext);
  return (
    <PasswordProtected>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        <Heading as="h2" size="xl">
          Merry Christmas {variant.name}!
        </Heading>
      </Box>
      <Box w="100%" p={5} shadow="md" borderWidth="1px">
        <UserContextProvider>
          <Home />
        </UserContextProvider>
      </Box>
    </PasswordProtected>
  );
}

export default App;
