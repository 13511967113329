import React, { useContext } from 'react';
import { UserContext } from '../../user';
import GiftSelection from '../gifts/GiftSelection';
import { Gift } from '../../gifts';
import Main from './Main';

function Home() {
  const { user, updateState } = useContext(UserContext);
  const [selectingGift, setSelectingGift] = React.useState<boolean>(false);

  const onGiftSelection = async (gift: Gift) => {
    setSelectingGift(false);
    if (gift.id === user.state?.giftId) {
      return;
    }
    updateState({ giftId: gift.id });
  };

  return (
    (selectingGift && (
      <GiftSelection
        selectedId={user.state?.giftId}
        onSelect={onGiftSelection}
      />
    )) || <Main onChooseGift={() => setSelectingGift(true)} />
  );
}

export default Home;
