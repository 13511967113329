import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import React from 'react';

type Props = {
  readonly isOpen: boolean;
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
  readonly header: string;
  readonly body: string;
  readonly confirmText?: string;
  readonly cancelText?: string;
};

function ConfirmModal({
  isOpen,
  onConfirm,
  onCancel,
  header,
  body,
  confirmText,
  cancelText,
}: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onConfirm}>
            {confirmText || 'Confirm'}
          </Button>
          <Button variant="ghost" onClick={onCancel}>
            {cancelText || 'Cancel'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;
