import { Button, Heading } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { getGift } from '../../gifts';
import { UserContext } from '../../user';
import Complete from './Complete';
import Welcome from './Welcome';

type Props = {
  readonly onChooseGift: () => void;
};

function Main({ onChooseGift }: Props) {
  const {
    user: { state },
  } = useContext(UserContext);

  const selectedGift = state?.giftId && getGift(state.giftId);

  return selectedGift ? (
    <Complete gift={selectedGift} onChangeGift={onChooseGift} />
  ) : (
    <Welcome onChooseGift={onChooseGift} />
  );
}

export default Main;
