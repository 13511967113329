import React from 'react';
import { Gift } from '../../gifts';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Text,
  Image,
  ButtonGroup,
  Badge,
  IconButton,
} from '@chakra-ui/react';
import PlayIcon from '../icons/PlayIcon';
import WebsiteIcon from '../icons/WebsiteIcon';

type Props = {
  gift: Gift;
  selected: boolean;
  onSelect: (gift: Gift) => void;
};

function GiftCard({ gift, selected, onSelect }: Props) {
  const { id, name, imageUri, description, websiteUri, youtubeUri } = gift;
  return (
    <Card
      id={id}
      direction="column"
      overflow="hidden"
      variant={selected ? 'elevated' : 'outline'}
    >
      <Image objectFit="cover" maxW="100%" src={imageUri} alt={name} />

      <Stack>
        <CardBody>
          {selected && <Badge colorScheme="green">Choosen</Badge>}
          <Heading size="md">{name}</Heading>
          <Text py="2">{description}</Text>
        </CardBody>

        <CardFooter>
          <ButtonGroup>
            <Button
              variant="solid"
              colorScheme={selected ? 'green' : 'blue'}
              onClick={() => onSelect(gift)}
            >
              {selected ? 'Keep Gift' : 'Choose Gift'}
            </Button>
            <IconButton
              aria-label="Website"
              icon={<WebsiteIcon />}
              variant="outline"
              colorScheme="yellow"
              as="a"
              href={websiteUri}
              target="_blank"
            />
            <IconButton
              aria-label="Play Video"
              icon={<PlayIcon />}
              variant="outline"
              colorScheme="red"
              hidden={!youtubeUri}
              as="a"
              href={youtubeUri}
              target="_blank"
            />
          </ButtonGroup>
        </CardFooter>
      </Stack>
    </Card>
  );
}

export default GiftCard;
