import axios, { AxiosBasicCredentials, AxiosError } from 'axios';

const API_URL = process.env.API_URL ?? 'https://api.xmas2023.hayden.gift';

interface ErrorResponse {
  readonly statusCode: number;
  readonly message?: string;
}

type ApiResponse<T = {}> =
  | (T & { readonly success: true })
  | (ErrorResponse & { readonly success: false });

interface UserState {
  readonly giftId?: string;
}

interface UserData {
  readonly name: string;
  readonly username: string;
  readonly lastLogin?: string;
  readonly state?: UserState;
}

const authUser = async (auth: AxiosBasicCredentials): Promise<ApiResponse> => {
  try {
    await axios.get(`${API_URL}/user/auth`, { auth });
    return { success: true };
  } catch (error: unknown | AxiosError) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (response) {
        return {
          success: false,
          statusCode: response.status,
          message: response.data.message,
        };
      }
    }
    return { success: false, statusCode: 500, message: JSON.stringify(error) };
  }
};

const getUser = async (
  auth: AxiosBasicCredentials
): Promise<ApiResponse<UserData>> => {
  try {
    const response = await axios.get<UserData>(`${API_URL}/user`, {
      auth,
    });
    return { ...response.data, success: true };
  } catch (error: unknown | AxiosError) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (response) {
        return {
          success: false,
          statusCode: response.status,
          message: response.data.message,
        };
      }
    }
    return { success: false, statusCode: 500, message: JSON.stringify(error) };
  }
};

const setUserState = async (
  auth: AxiosBasicCredentials,
  state: UserState
): Promise<ApiResponse> => {
  try {
    const response = await axios.post(`${API_URL}/user/state`, state, { auth });
    return { success: true };
  } catch (error: unknown | AxiosError) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (response) {
        return {
          success: false,
          statusCode: response.status,
          message: response.data.message,
        };
      }
    }
    return { success: false, statusCode: 500, message: JSON.stringify(error) };
  }
};

const getAllUsers = async (
  auth?: AxiosBasicCredentials
): Promise<ApiResponse<{ users: UserData[] }>> => {
  try {
    const response = await axios.get<UserData[]>(
      `${API_URL}/${!auth ? 'public/users' : 'users'}`,
      { auth }
    );
    return { success: true, users: response.data };
  } catch (error: unknown | AxiosError) {
    if (axios.isAxiosError(error)) {
      const { response } = error;
      if (response) {
        return {
          success: false,
          statusCode: response.status,
          message: response.data.message,
        };
      }
    }
    return { success: false, statusCode: 500, message: JSON.stringify(error) };
  }
};

const api = {
  authUser,
  getUser,
  setUserState,
  getAllUsers,
} as const;

export default api;
