import {
  Button,
  Center,
  Divider,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import signatureUri from '../../assets/images/uncle-david.png';
import { VariantContext } from '../../variants';

type Props = {
  readonly onChooseGift: () => void;
};

function Welcome({ onChooseGift }: Props) {
  const variant = useContext(VariantContext);
  return (
    <Stack>
      <Heading as="h3" size="md">
        You choose your gift!
      </Heading>
      <Text fontSize="lg">
        I am not always sure to get for you for a gift these days, and recently
        think I prefer to do something with you as a gift instead.
      </Text>
      <Text fontSize="lg">
        So I'm letting you choose what you'd like to do as my Christmas gift to
        you this year!
      </Text>
      <Text fontSize="lg">
        You may wish to liaise with your {variant.siblingType} and your cousins
        if you want to do the same things as them. Click below to choose!
      </Text>
      <Text align="center">Love</Text>
      <Center>
        <Image maxW="50%" src={signatureUri} alt="Uncle David x" />
      </Center>
      <Divider />
      <Button variant="solid" colorScheme="green" onClick={onChooseGift}>
        Choose Gift
      </Button>
    </Stack>
  );
}

export default Welcome;
