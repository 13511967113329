import React from 'react';
import { gifts } from '../../gifts';
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Text,
  Image,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import PlayIcon from '../icons/PlayIcon';
import WebsiteIcon from '../icons/WebsiteIcon';
import { User } from '../../user';

type Props = {
  user: User;
};

function UserGiftCard({ user }: Props) {
  const gift = gifts.find((g) => g.id === user.state?.giftId);
  return (
    <Card
      id={user.username}
      direction="column"
      overflow="hidden"
      variant={gift ? 'elevated' : 'outline'}
    >
      {gift && (
        <Image
          objectFit="cover"
          maxW="100%"
          src={gift.imageUri}
          alt={gift.name}
        />
      )}

      <Stack>
        <CardBody>
          <Heading size="lg">{user.name}</Heading>
          {gift ? (
            <>
              <Heading size="small">{gift.name}</Heading>
              <Text py="2">{gift.description}</Text>
            </>
          ) : (
            <Heading size="small">No Gift Chosen Yet</Heading>
          )}
          <Text py="2">Last Login: {user.lastLogin || 'NEVER'}</Text>
        </CardBody>

        <CardFooter>
          {gift && (
            <ButtonGroup>
              <IconButton
                aria-label="Website"
                icon={<WebsiteIcon />}
                variant="outline"
                colorScheme="yellow"
                as="a"
                href={gift.websiteUri}
                target="_blank"
              />
              <IconButton
                aria-label="Play Video"
                icon={<PlayIcon />}
                variant="outline"
                colorScheme="red"
                hidden={!gift.youtubeUri}
                as="a"
                href={gift.youtubeUri}
                target="_blank"
              />
            </ButtonGroup>
          )}
        </CardFooter>
      </Stack>
    </Card>
  );
}

export default UserGiftCard;
