import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import signatureUri from '../../assets/images/uncle-david.png';
import { Gift } from '../../gifts';
import PlayIcon from '../../components/icons/PlayIcon';
import WebsiteIcon from '../../components/icons/WebsiteIcon';

type Props = {
  readonly gift: Gift;
  readonly onChangeGift: () => void;
};

function Complete({ gift, onChangeGift }: Props) {
  return (
    <Stack>
      <Heading as="h3" size="md">
        You chose {gift.name}!
      </Heading>
      <Image maxW="100%" src={gift.imageUri} alt={gift.name} />
      <Text fontSize="lg">{gift.description}</Text>
      <Center>
        <ButtonGroup>
          <Button
            leftIcon={<WebsiteIcon />}
            variant="outline"
            colorScheme="yellow"
            as="a"
            href={gift.websiteUri}
            target="_blank"
          >
            Website
          </Button>
          <Button
            leftIcon={<PlayIcon />}
            variant="outline"
            colorScheme="red"
            hidden={!gift.youtubeUri}
            as="a"
            href={gift.youtubeUri}
            target="_blank"
          >
            Video
          </Button>
        </ButtonGroup>
      </Center>
      <Divider />
      <Button onClick={onChangeGift}>Change Gift</Button>
      <Divider />
      <Text fontSize="lg">I look forward to going with you soon!</Text>
      <Text align="center">Love</Text>
      <Center>
        <Image maxW="50%" src={signatureUri} alt="Uncle David x" />
      </Center>
      <Alert mt={4} status="info">
        <AlertIcon />
        Subject to parental approval and being able to arrange an appropriate
        date when all parties are available.
      </Alert>
    </Stack>
  );
}

export default Complete;
